.pageBackground {
  background-color: black;
  background-image: url("./bg.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  margin-bottom: 30px;
  padding-bottom: 50px;

}

.pageContentArea {
  background-color: var(--white);
  color: var(--black);
  font-family: var(--Digia-font);
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 10px;
  width: 85vw;
}

h6 {
  font-size: 14px !important;
}

.updateButtons {
  margin-bottom: 10px;
  margin-left: 0;
  padding-left: 0;
}

div.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.updateBtn, .adminBtn, .applyBtn {
  margin-left: 15px;
}

@media(max-width: 355px) {
  .adminBtn {
    margin-top: 20px;
  }

  .applyBtn {
    margin-left: 15px;
    margin-top: 20px;
  }
}
