// Override default variables before the import
$theme-colors: (
  "primary": #01acbf,
  "danger": #ff4136,
  "blue": #172B4D,
  "gray": #A4ACAC,
  "green": #31806F,
  "yellow": #EEBA37,
  "red": #AA0B0B,
  "turquoise": #0883A7
);

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1400px
);

$link-color: #2a2a2a;

:root {
  --blue: #172B4D !important;
  --white: #ffffff !important;
  --black: #1C1C1C !important;
  --green: #059D7D !important;
  --turquoise: #0883A7 !important;
  --yellow: #EEBA37 !important;
  --red: #AA0B0B !important;
  --grey: #A4ACAC !important;
  --lightGrey: #e6e6e6 !important;

  --digia-font: Raleway, sans-serif !important;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    background-color: transparent;
    border: 0;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
