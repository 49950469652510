.btn-blue {
    color: var(--white);
    background: var(--blue);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-blue:hover {
    color: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), var(--blue);
    border: 1px solid; 
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-yellow {
    text-decoration-color: var(--white);
    color: var(--white);
    background: var(--yellow);
    border: 1px solid; 
    border-color: (--white);
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-yellow:hover {
    text-decoration-color: var(--white);
    color: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), var(--yellow);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
                }
.btn-gray {
    color: var(--white);
    text-decoration-color: var(--white);
    background: var(--grey);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-gray:hover {
    color: var(--white);
    text-decoration-color: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%),  var(--grey);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-green {
    color: var(--white);
    background: var(--green);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);

}
.btn-green:hover {
    color: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), var(--green);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-red {
    color: var(--white);
    background: var(--red);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);

}
.btn-red:hover {
    color: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), var(--red);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn-turquoise {
    color: var(--white);
    background: var(--turquoise);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);

}
.btn-turquoise:hover {
    color: var(--white);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), var(--turquoise);
    border: 1px solid;
    border-color: var(--white) !important;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
