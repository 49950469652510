.info-card {
    border: 0 !important;
}
.card-header {
    background-color: var(--white) !important;
    border-bottom: 0 !important;
    margin-left: -5px;
}

.card-body {
    padding: 0 1.25rem 1.25rem;
}

.fa-angle-down {
    margin-left: 10px;
    padding-top: 10px;
    vertical-align: bottom;
}