.modalBody {
  font-size: 12px !important;
}
.googleLink {
  font-size: 12px;
}
.googleLink:hover{
  font-weight: bold;
  text-decoration: none;
}
.trendLink {
  margin-left: 20px;
}

.modalBadge {
  float: right;
}
.modalHeadLine {
  font-size: 14px;
  font-weight: 700;
  background-color: #F2F2F2;

}
.costCenterLabel {
  margin-top: -5px !important;
}
.costCenterDataRow {
  margin-bottom: 8px;
  margin-left: 5px !important;
}
.costCenterDataLabel {
  font-weight: 700;
}
.modal-90w {
  width: 80%;
  max-width: 80% !important;
}
.card-header {
  background-color: var(--white) !important;
  border-bottom: 0 !important;
  margin-left: -5px;
}

.techModalHeader {
  margin-left: 18px;
  width: 100%;
}

.form-row {
  margin-bottom: 10px !important;
}

label {
  font-weight: 700;
}

.formButtons {
  float:right;
  margin-bottom: 15px !important;
}

.form-control {
  font-family: var(--digia-font) !important;
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .costCenterLabel {
    margin: 0 0 15px -15px;
  }

  .costCenterRowButtons {
    margin: 15px 0 5px -15px;


  }
}

@media (min-width: 1200px) {
  label {
    margin-left: 15px
  }

  .form-control {
    margin-left: -20px;
    margin-right: 80px;
  }

  .formButtons {
    padding-left: 55px !important;
  }

  .form-check-inline {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-top: 7px !important;
  }

  .form-check-label {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 10px !important;
  }

  .radioButtons {
    margin-left: 0px;
    margin-right: 00px !important;
  }
}
