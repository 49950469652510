.quardantLink {
  font-size: 12px !important;
}

.quardantLink:hover {
  font-weight: bold;
  text-decoration: none;
}

.buttonRight {
  margin-left: 10px;
}

.right {
   float: right;
}