  .topCostcenter {
    position: relative;
    z-index:60;
  }

  .topQuadrants {
    position: relative;
    z-index:50;
  }

  .topRings {
    position: relative;
    z-index:40;
  }

  .menu {
    border-bottom: 1px var(--lightGrey) solid;
    margin-top: 10px;
    padding-right: 10px;
    right: 0;
    top: 60px;
  }

  .menu ul {
    height: auto !important;
    list-style: none;
    margin: 5px 15px 5px 0;
    padding: 5px;
    z-index: 6;
  }

  .menu:hover {
    background-color: var(--lightGrey);
  }

  .menu-list {
    border: none !important;
    background-color: var(--white);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    position: absolute !important;
    z-index: inherit 30;
    width: 313px
  }

  .menu-list:hover {
    background-color: var(--white) !important;
  }

  .menu li {
    margin-bottom: 5px;
    text-decoration: none;
    z-index: 14;
    position: relative;
  }

  .menu li:hover {
    font-weight: bold !important;
  }

  .active {
    font-weight: 600;
  }

  .dropdownIcon {
    float: right;
    margin-top: 3px;
    margin-right: -20px;
  }

  .filterAmount {
    float: right;
    margin: -1px 3px 0 10px;
  }
