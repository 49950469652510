text[class^="legend"] {
  font-size: 10px;
}

#rink text {
  font-size: 9px;
}

.selectors {
  max-width: 60vw;
  margin: 0 auto;
}

.chosen_filters {
  height: 30px;
  position: relative;
  padding-top: 2px;
  padding-left: 12px;
}

.filterBadge {
  background-color: var(--lightGrey);
  color: var(--black);
  cursor: pointer;
  margin-right: 5px;
  padding-top: 7px !important;
}

.trashIcon, .closeIcon {
  margin: 0 5px 0 10px;
}
