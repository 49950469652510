.badge {
  font-size: 12px !important;
  margin-left: 2px;
  vertical-align: text-bottom;
}
.badge-success {
  background-color: var(--green) !important;
}

.badge-info {
  background-color:var(--turquoise) !important;
  margin-top: 15px;
}

.badge-warning {
  background-color: var(--yellow) !important;
  color: var(--white) !important;
}

.badge-danger {
  background-color:var(--red) !important;
  margin-top: 15px;
}

@media (min-width: 991px) {
  .scroll {
    overflow-y: scroll;
    max-height: 340px;
    margin-bottom: 20px;
  }

  .scroll::-webkit-scrollbar {
    width: 8px;
  }

  .scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }

  .scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  }
}
