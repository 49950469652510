header {
    background-color: var(--black);
    max-height: 80px;
    min-height: 55px;


}
.header-external {
    background-color: var(--white);
    max-height: 80px;
    min-height: 55px;
}
.logo {
    margin: auto;
    padding: 20px;
    width: 95vw;
}
img { 
    height: 1vh;
    max-height: 75px;
    min-height: 35px;
}
.ext-logo-img {
    height: 18vh; 
    
}
span {
    font-family: var(--Digia-font);
    font-weight: 600;
    margin-left: 20px; 
    margin-top: 5px;
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
}
.ext-logo-text {
    color: var(--black);
}

@media (min-width: 1200px) {
    .logo {
        max-width: 1400px;
    }
}

@media (min-width: 1400px) {
    .ext-logo-img {
        margin-top: -15px; 
    }  
}

@media (max-width: 1024px) {
    .ext-logo-img {
        height: 4vh; 
    }    
}

@media (max-width: 376px) {
    button {
        margin-top: -8px;
    }
    img { 
        max-height: 25px;
        min-height: 25px;
    }
    span {
        margin-left: 5px; 
    }
}

@media (max-width: 320px) {
   
    img { 
        max-height: 15px;
        min-height: 15px;
    }
    span {
        font-size: 12px;
        margin-left: 5px; 
    }
}