.searchField {
    margin: 20px 15px 0;
    padding-left: 5px !important;
}

.clearSearchBtn {
    margin-top: 20px;
    margin-right: 5px;
}

.timesIcon {
    margin-left: 15px;
    margin-right: 5px;
}


@media(max-width: 375px) {
    .clearSearchBadge {
        margin-left: 15px !important;
        padding: 5px !important;
    }
}
